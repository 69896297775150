<template>
    <div :style="{width:'100%',height:`${height}px`,marginTop:'20px'}" id="container">
    </div>
</template>
<script>
export default {
    props: {
        address: {
            type: String,
            default: '北京故宫博物院'
        },
        height: {
            type: Number,
            default: 600
        }
    },
    mounted() {
        console.log(this.address)
        this.$nextTick(() => {
            this.initMap()
        })
    },
    data() {
        return {
            map: null,
            onMapLoad:'onMapLoad'
        }
    },
    methods: {
        initMap() {
            // var map = new AMap.Map("container", {
            //     zoom: 20
                
            // })
            // var geocoder = new AMap.Geocoder({
            //     city: "上饶", //城市设为北京，默认：“全国”
            // })
            
            // var marker = new AMap.Marker()
            //  geocoder.getLocation(this.address, function(status, result) {
            //     console.log(status)
            //     console.log(result.geocodes[0])
            //     if (status === 'complete' && result.geocodes.length) {
            //         console.log(result.geocodes[0])
            //         var lnglat = result.geocodes[0].location
            //         marker.setPosition(lnglat)
            //         map.add(marker)
            //         map.setFitView(marker)
            //     }else{
                
            //     }
            // })
            
            var map = new BMapGL.Map('container');
            map.centerAndZoom(new BMapGL.Point(116.331398,39.897445), 12);
            //创建地址解析器实例
            var myGeo = new BMapGL.Geocoder();
            // 将地址解析结果显示在地图上，并调整地图视野
            myGeo.getPoint(this.address, function(point){
                if(point){
                    map.centerAndZoom(point, 16);
                    map.addOverlay(new BMapGL.Marker(point, {title: this.address}))
                }else{
                    alert('您选择的地址没有解析到结果！');
                }
            }, '广信区')



        }
    }

}
</script>
<style lang="scss" scoped>

</style>