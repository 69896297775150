<template>
    <div class="gongsi">
        <div class="company-hotjob">
            <div class="hotjob-inner user-content">
                <div class="hotjob-title flex" v-show="jobList.length>0">
                    <div class="job-left">热招职位</div>
                    <div class="job-right" @click="routeToCjobs">查看全部{{jobTotal}}个职位</div>
                </div>
                <div class="hotjob-list flex" v-show="jobList.length>0">
                    <div class="hotjob-item" v-for="(item, index) in jobList" :key="`jobList${index}`">
                        <div class="hot-top flex">
                            <div class="top-left">{{item.workName}}</div>
                            <div class="top-right">{{item.wagesName}}</div>
                        </div>
                        <div class="hot-bottom">{{item.workYears}}·{{item.workEducation}}.{{item.workAreaName}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gongsi-desc-warpper">
            <div class="gongsi-desc user-content flex">
                <div class="c-detail">
                   <div class="c-sec">
                       <div class="c-title g-title">公司简介</div>
                       <div class="c-introduction" :class="{'drow':!drowFlag,'more':drowFlag}">
                           <pre class="c-message">{{companyInfo.companyInfo||'暂未填写公司简介'}}</pre>
                            <div class="is-more" :class="{'flag':drowFlag}" @click="changeDrowFlag" v-if="companyInfo.companyInfo">
                                <span v-if="!drowFlag"><i class="el-icon-bottom"></i>查看更多</span>
                                <span v-else><i class="el-icon-top"></i>···收起</span>
                            </div>
                       </div>
                       <div class="c-business">
                           <div class="business-title g-title">公司信息</div>
                           <div class="com-name">{{companyInfo.companyName}}</div>
                           <div class="com-other">
                               <div class="oten-item flex">
                                   <span class="other-t">招聘负责人代表：</span>
                                   <span>{{companyInfo.legalName}}</span>
                               </div>
                               <div class="oten-item flex">
                                   <span class="other-t">注册资金：</span>
                                   <span>{{companyInfo.register}}万</span>
                               </div>
                               <div class="oten-item flex">
                                   <span class="other-t">公司性质：</span>
                                   <span>{{companyInfo.companyNatureName}}</span>
                               </div>
                               <div class="oten-item flex">
                                   <span class="other-t">公司规模：</span>
                                   <span>{{companyInfo.companyScale}}</span>
                               </div>
                               <div class="oten-item flex">
                                   <span class="other-t">公司联系电话：</span>
                                   <span>{{companyInfo.companyContactsPhone}}</span>
                               </div>
                               <div class="oten-item">
                                   <span class="other-t">公司地址：</span>
                                   <span>{{companyInfo.companyAreaName}}{{companyInfo.companyAddress}}</span>
                                   <vue-map :height="300" v-if="address" :address="address"></vue-map>
                               </div>
                           </div>
                       </div>
                   </div>
                   
                </div>
                <div class="c-slider flex">
                    <!-- <div class="manager-list">
                        <div class="s-tile g-title">公司高管</div>
                        <div class="s-person flex">
                            <div class="avatar">
                                <img src="../../../assets/img/users/avatar.png" alt="">
                            </div>
                            <div class="p-desc">
                                <div class="p-name">{{companyInfo.legalName}}</div>
                                <div class="p-begin">创始人</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="picture-list" v-if="companyImgList.length>0">
                        <div class="p-tile g-title">公司环境</div>
                        <div class="p-carousel">
                            <el-carousel trigger="click" height="200px">
                                <el-carousel-item v-for="(item, index) in companyImgList" :key="`companyImgList${index}`">
                                    <div class="carousel-item" :style="{'backgroundImage': `url(${item})`}">
                                        <!-- <img src="../../../assets/img/users/11.png" alt=""> -->
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                    <div class="video-list" v-if="companyVideo">
                        <div class="p-tile g-title">宣传视频</div>
                        <div class="v-video">
                            <video width="304" controls autoplay>
                                <source :src="companyVideo" type="video/mp4">
                                您的浏览器不支持 video 标签。
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userRequest from '../../../api/user'
import VueMap from '../../../components/vueMap/Index'
export default {
    components: {
        VueMap
    },
    mounted () {
        let query = this.$route.query  
        if (!query.companyId) {
            this.$router.back()
            return
        }
        this.companyId = query.companyId
        this.getCompanyDetail()
        this.jobForm.memberId = query.companyId
        this.getJobListByCompanyId()
    },
    data() {
        return {
            drowFlag: false,
            companyId: null,
            companyInfo: {},
            address: '',
            jobForm: {memberId: null, pageNum: 1, pageSize: 3},
            jobList: [],
            jobTotal: 0,
            companyImgList: [],
            companyVideo: ''
        }
    },
    methods: {
        changeDrowFlag() {
            this.drowFlag = !this.drowFlag
            // console.log(this.drowFlag)
        },
        getCompanyDetail() {
            userRequest.getCompanyDetail({memberId: this.companyId}).then((res) => {
                if (res.code === 200) {
                    this.companyInfo = res.data
                    const address = res.data.companyAreaName.split('-')
                    this.address = address[address.length - 1] + res.data.companyAddress
                    // console.log('address', this.address)
                    res.data.companyAmbientImg && (this.companyImgList = res.data.companyAmbientImg.split(','))
                    res.data.companyPropaganda && (this.companyVideo = res.data.companyPropaganda)
                }
            })
        },
        getJobListByCompanyId() {
            userRequest.getJobListByCompanyId(this.jobForm).then((res) => {
                if (res.code === 200) {
                    this.jobList = res.data.list
                    this.jobTotal = res.data.total
                }
            })
        },
        routeToCjobs() {
            this.$router.push({
                path: '/user/companyNews/cJobs',
                query: {
                    companyId: this.companyId
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
    .gongsi{
        background: #fff;
        .g-title{
            font-size: 20px;
            color: #333333;
            line-height: 20px;
            margin-bottom: 22px;
            font-weight: bolder;
        }
        .c-message{
            min-height: 120px;
        }
        .company-hotjob{
            background: #f2f2f5;
            padding: 25px 0 18px;
            .hotjob-inner{
                .hotjob-title{
                    justify-content: space-between;
                    .job-left{
                        font-size: 16px;
                    }
                    .job-right{
                        font-size: 14px;
                        color: #9fa3b0;
                        user-select: none;
                        cursor: pointer;
                        &:hover{
                            color: $main;
                        }
                    }
                }
                .hotjob-list{
                    margin-top: 20px;
                    .hotjob-item{
                        box-sizing: border-box;
                        width: 33.333%;
                        padding: 19px 20px 17px;
                        border: 1px #fff solid;
                        background: #fff;
                        margin-right: 15px;
                        &:nth-child(3n+3){
                            margin-right: 0;
                        }
                        .hot-top{
                            justify-content: space-between;
                            margin-bottom: 10px;
                            .top-left{
                                font-size: 16px;
                            }
                            .top-right{
                                color: $main;
                                float: right;
                                font-size: 16px;
                            }
                        }
                        .hot-bottom{
                            padding-top: 7px;
                            color: #9fa3af;
                        }
                    }
                }
            }
        }
        .gongsi-desc-warpper{
            padding-top: 40px;
            .gongsi-desc{
                .c-detail{
                    width: 836px;
                    padding-right: 25px;
                    padding-bottom: 30px;
                    border-right: 1px #f5f7f9 solid;
                    .c-sec{
                        .c-introduction{
                            position: relative;
                            line-height: 22px;
                            &.drow{
                                max-height: 180px;
                                overflow: hidden;
                            }
                            &.more{
                                height: auto;
                            }
                            .is-more{
                                position: absolute;
                                cursor: pointer;
                                z-index: 100;
                                box-sizing: border-box;
                                font-size: 14px;
                                width: 86px;
                                right: 0;
                                bottom: 0;
                                height: 25px;
                                padding-right: 10px;
                                text-align: right;
                                background: url('../../../assets/img/users/layer-more.png');
                                i{
                                    margin-right: 3px;
                                }
                                &.flag{
                                    bottom: -25px;
                                }
                            }
                        }
                    }
                    .c-business{
                        margin-top: 30px;
                        .com-name{
                            font-size: 16px;
                            margin-bottom: 13px;
                        }
                        .com-other{
                            border: 1px #d9dadf solid;
                            padding: 15px 25px;
                            padding-right: 12px;
                            .oten-item{
                                padding: 10px 0;
                                font-size: 14px;
                                .other-t{
                                    color: #9fa3b0;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                } 
                .c-slider{
                    flex: 1;
                    align-items: flex-end;
                    flex-direction: column;
                    .manager-list{
                        width: 304px;
                        max-height: 130px;
                        margin-bottom: 30px;
                        .s-person{
                            .avatar{
                                width: 60px;
                                height: 60px;
                                overflow: hidden;
                                margin-right: 10px;
                                img{
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                            .p-desc{
                                .p-name{
                                    font-size: 20px;
                                    padding-bottom: 10px;
                                }
                                .p-begin{
                                    margin-top: 2px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    .picture-list{
                        width: 304px;
                        margin-bottom: 30px;
                        .p-carousel{
                            width: 304px;
                            .carousel-item{
                                width: 304px;
                                height: 200px;
                                // background: url('../../../assets/img/users/11.png') no-repeat;
                                background-size: 100% 100%;
                            }
                        }
                    }
                    .video-list{
                        width: 304px;
                    }
                }
            }
        }
        
    }
</style>